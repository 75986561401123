import React from 'react';
import { Transition, Dialog } from "@headlessui/react"
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment, PropsWithChildren } from "react"
import { Button } from "../button/Button.component";
import { Translate } from '../text/Text.component';

export interface SlideoverProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    slideoverTitle: string;
    showOpenTag?: boolean;
    translateTitle?: boolean;
}

export const Slideover: React.FunctionComponent<PropsWithChildren<SlideoverProps>> = (props: PropsWithChildren<SlideoverProps>): JSX.Element => {

    const { open, setOpen, children, slideoverTitle, showOpenTag = false, translateTitle = false } = props;

    return (
        <>
            {
                showOpenTag ?
                <div onClick={() => setOpen(true) } className='z-40 fixed right-0 top-28 w-8 pr-2 pl-1 py-12 h-32 border-y-2 border-black dark:border-stone-600 border-l-2 rounded-l-lg bg-stone-200 dark:bg-stone-900'>
                    <ArrowLeftIcon className="w-6 h-6 text-black dark:text-stone-400 pt-1" />
                </div> : null
            }
            <div className="z-40 absolute top-0 left-0 h-screen">
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-40" onClose={setOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >

                            <div className="z-40 fixed inset-0 bg-stone-500 dark:bg-stone-700 bg-opacity-75 dark:bg-opacity-50 transition-opacity overflow-hidden" />
                            
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-hidden z-50">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 overflow-hidden">
                                    <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                    >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl overflow-hidden h-screen">
                                        <div className="flex flex-col bg-white dark:bg-stone-900 py-6 shadow-xl h-full overflow-auto">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between h-7">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200 h-7">
                                                        { 
                                                            translateTitle ? 
                                                            <Translate translation_key={slideoverTitle} /> :
                                                            slideoverTitle 
                                                        }
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <Button 
                                                            className="relative rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 h-7 w-7"
                                                            onClick={() => setOpen(false)}
                                                            color="transparent"
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                { children }
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </>
    )
}