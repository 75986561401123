import axios, { AxiosResponse } from "axios";
import { currentShippingScanItemAtom, IShippingScanRequest, IShippingScanResponse, shippingScanLoading } from "./types";
import { environment } from "../../environment";
import { useLogin } from "../login";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useAtom } from "jotai";


type ScanItem = (query: IShippingScanRequest) => Promise<IShippingScanResponse | null>;
type VoidLabel = (shipmentId: number) => Promise<IShippingScanResponse | null>;

type UseShippingKeyFunctions = {
    scan: ScanItem,
    voidLabel: VoidLabel
}

type UseShipping = [
    IShippingScanResponse | null,
    UseShippingKeyFunctions
];

export const useShipping = (): UseShipping => {
    const [employee, authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();
    const [scan, setScan] = useAtom(currentShippingScanItemAtom);
    const [, setScanLoading] = useAtom(shippingScanLoading);

    const scanItem: ScanItem = async (query: IShippingScanRequest): Promise<IShippingScanResponse | null> => {
        if (employee !== null) {
            const scanResponse: AxiosResponse<IShippingScanResponse> | void = await axios.post(`${environment.shippingUrl}/scan-item`, {...query, employee: employee.id}, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
    
            if (scanResponse) {
                setScan(scanResponse.data);
                return scanResponse.data;
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while performing box scan'
                })
                setScan(null);
                setScanLoading(false);
                return null;
            }
        } else {
            loginFunctions.logout();
            return null;
        }
    }

    const voidLabel: VoidLabel = async (shipmentId: number): Promise<IShippingScanResponse | null> => {
        if (employee !== null) {
            const scanResponse: AxiosResponse<IShippingScanResponse> | void = await axios.post(`${environment.shippingUrl}/void-label`, { shipmentId }, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
    
            if (scanResponse) {
                setScan(scanResponse.data);
                return scanResponse.data;
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while voiding the label'
                })
                setScan(null);
                setScanLoading(false);
                return null;
            }
        } else {
            loginFunctions.logout();
            return null;
        }
    }

    const shippingKeyFunctions: UseShippingKeyFunctions = {
        scan: scanItem,
        voidLabel: voidLabel
    }

    return [
        scan,
        shippingKeyFunctions
    ]
}