import { atom } from "jotai";

export const shopifyProductsLoadingAtom = atom<boolean>(false);

export interface IShopifyProduct {
    id: string;
    title: string;
    featuredImage: {
        url: string;
    };
    cursor: string;
    wallmates_design_exists: boolean;
    combinedListingChildren?: number[];
}

export interface IGetProductsResponse {
    hasNext: boolean;
    hasBefore: boolean;
    results: IShopifyProduct[];
    count: number;
}
