import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import {
    IFetchGeneratePreviewRequestBody,
    IGeneratedPreview,
    IGeneratePreviewAttributes,
    previewAtom,
    previewLoadingAtom,
} from "./types";

type GenerateMockupType = (body: IFetchGeneratePreviewRequestBody) => Promise<IGeneratedPreview | void>;

type GeneratedPreviewsKeyFunctions = {
    generateMockup: GenerateMockupType;
    resetPreviews: () => void;
}

type UsePreviews = [
    { [key: number] : IGeneratedPreview },
    GeneratedPreviewsKeyFunctions
];

const defaultAttributes: IGeneratePreviewAttributes = {
    pattern_scale: '100',
    shadow_opacity: '100',
    enhance_shadows: false,
}

export const useGeneratedPreviews = (): UsePreviews => {
    const [previews, setPreviews] = useAtom(previewAtom);
    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setPreviewLoading = useSetAtom(previewLoadingAtom);

    function base64ToFile(base64String: string, filename: string) {
        // Split the base64 string to get the content type and data
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/png';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
      
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
      
        return new File([u8arr], filename, { type: mime });
      }

    const generateMockup = async (body: IFetchGeneratePreviewRequestBody): Promise<IGeneratedPreview | void> => {
        body.attributes = {
            ...defaultAttributes,
            ...body.attributes
        }
        body.attributes.shadow_opacity = parseInt(body.attributes.shadow_opacity as string) as any;
        body.attributes.pattern_scale = parseInt(body.attributes.pattern_scale as string) as any;


        const file = base64ToFile(body.data_url as string, `${body.mockup_id}-${body.product_id}.png`);

        // const file = getFileFromBase64(body.data_url as string, `${body.mockup_id}-${body.product_id}.jpg`);
        const formData = new FormData();
        formData.append('mockup_file', file);
        const data = {
            mockup_id: body.mockup_id,
            product_id: body.product_id,
            attributes: body.attributes
        }
        formData.append('data', JSON.stringify(data));

        setPreviewLoading(true);
        return await Axios.post(`${environment.mockupsUrl}/generate-mockup`, formData, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
            .then((result: AxiosResponse<IGeneratedPreview>) => {
                if (result.data && body.mockup_id === result.data.mockup_id) {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'info',
                        text: 'Mockup generated successfully! Mockups can be found under the products > "Generated Mockups" section.'
                    })
                    return result.data;
                } else {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'error',
                        text: 'There was an error while fetching the generated previews. Please try again later.'
                    })
                    setPreviews((prev) => {
                        return {
                            ...prev,
                            [body.mockup_id]:  result?.data || 'Unknown error'
                        }
                    });
                }
            })
            .catch((err: any) => errorCatcher(err, loginFunctions.logout))
            .finally(() => setPreviewLoading(false));
    }

    const keyFunctions: GeneratedPreviewsKeyFunctions = {
        generateMockup: generateMockup,
        resetPreviews: () => setPreviews([]),
    }

    return [
        previews,
        keyFunctions
    ]
}

