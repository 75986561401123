import { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type ToggleProps = {
    onClick: () => void;
    selected?: boolean;
}

export const Toggle = function Toggle({ onClick, selected }: ToggleProps) {
  const [enabled, setEnabled] = useState(selected)

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      onClick={onClick}
      className={classNames(
        enabled ? 'bg-emerald-400' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}