import { atom } from "jotai";
  
export const checklistItemsAtom = atom<IChecklistItem[]>([]);
export const completedListAtom = atom<IChecklistItem[]>([]);
export const checklistItemsLoadingAtom = atom<boolean>(false);
export const completionMapsAtom = atom<ICustomerChecklistCompletionMap[]>([]);
export const completionMapsLoadingAtom = atom<boolean>(false);
export const completedListLoadingAtom = atom<boolean>(false);
export const auditingAtom = atom<boolean>(false);
export const completedPercentageAtom = atom<number>(0);
export const auditCompletedPercentageAtom = atom<number>(0);
export const auditErrorsAtom = atom<IAuditError[]>([]);

export enum ICompletion {
    MANUAL = 'manual',
    AUTOMATIC = 'automatic',
    COLUMN_CHECK = 'column',
    ROW_CHECK = 'row',
    LOCAL_STORAGE = 'local',
    PAGE_VISIT = 'page',
    PRESIGNED_URL = 'presigned',
}

export interface IAuditError {
    message: string;
    item: IChecklistItem;
}

export interface IChecklistItem {
    id?: string | number;
    name: string;
    description: string;
    completion_type: ICompletion;
    completion_metadata?: string;
    link?: string;
    rank: number | string;
    active?: boolean;
    created_at?: string;
    updated_at?: string;
    completed?: boolean;
}

export interface ICustomerChecklistCompletionMap {
    id?: string | number;
    brand_id: string | number;
    completed_item_id: string | number;
    completion_date?: string;
}

export interface ICompletionMetadata {
    url?: string | Location['pathname'];
    method?: string;
    headers?: { [key: string]: string; };
    data?: string;
    values?: any[];
    key?: string;
    columns?: string[];
    status?: number;
    bucket?: string;
}

export const CompletionTypeMap: any = {
    [ICompletion.AUTOMATIC]: '{}',
    [ICompletion.MANUAL]: '{}',
    [ICompletion.LOCAL_STORAGE]: '{\n  "key": "",\n  "columns": "",\n  "values": "",\n}',
    [ICompletion.ROW_CHECK]: '{\n  "url": "",\n  "values": 200\n}',
    [ICompletion.COLUMN_CHECK]:  '{\n  "url": "",\n  "columns": [],\n values: []\n}',
    [ICompletion.PAGE_VISIT]: '{\n  "url": ""\n}',
}