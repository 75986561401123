import { useAtom } from "jotai";
import Axios from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IMockupCategory, mockupCategoryAtom } from "./types";

type SetMockupCatoryType = (payload: IMockupCategory[]) => void;
type FetchMockupCategories = () => void;

type MockupCategoryKeyFunctions = {
    get: FetchMockupCategories
}

type UseMockupCategories = [
    IMockupCategory[],
    SetMockupCatoryType,
    MockupCategoryKeyFunctions
];

export const useMockupCategories = (): UseMockupCategories => {
    const [mockupCategories, setMockupCategories] = useAtom(mockupCategoryAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getMockupCategories = () => {
        const fetchCategories = async (setter: SetMockupCatoryType): Promise<void> => {
            const result = await Axios.get(environment.mockupCategoriesUrl, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (result) {
                setter(result.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting mockup categories. Please try again later.'
                })
            }
        }
        fetchCategories(setMockupCategories);
    }

    const keyFunctions: MockupCategoryKeyFunctions = {
        get: getMockupCategories
    }

    return [
        mockupCategories,
        setMockupCategories,
        keyFunctions
    ]
}