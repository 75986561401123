/*
TODO: Update this component to use your client-side framework's link
component. We've provided examples of how to do this for Next.js,
Remix, and Inertia.js in the Catalyst documentation:

https://catalyst.tailwindui.com/docs#client-side-router-integration
*/

import { DataInteractive as HeadlessDataInteractive } from '@headlessui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Link = React.forwardRef(function Link(
  props: { href: string; action?: () => void; } & React.ComponentPropsWithoutRef<'a'>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref: React.ForwardedRef<any>
) {
  const { action, href, ...passProps } = props;
  const navigate = useNavigate();

  return (
    <HeadlessDataInteractive>
      <span key={href} onClick={() => {

        if (action) {
          action();
        }

        if (href) {
          if (href[0] === '/') {
            navigate(href)
          } else {
            window.open(href, '_blank');
          }
        }
      }}
      { ...passProps} />
    </HeadlessDataInteractive>
  )
})
