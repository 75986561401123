import { Button as HeadlessButton, type ButtonProps as HeadlessButtonProps } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import { TouchTarget } from './../button/Button.component'
import { Link } from './../link/Link.component'

type AvatarProps = {
  src?: string | null
  square?: boolean
  rounded?: boolean
  initials?: string
  alt?: string
  className?: string
  width?: string
  height?: string
}

export function Avatar({
  src = null,
  square = false,
  rounded = true,
  initials,
  alt = '',
  className,
  width,
  height,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      data-slot="avatar"
      className={clsx(
        className,

        // Basic layout
        'inline-grid align-middle *:col-start-1 *:row-start-1',

        // Add the correct border radius
        square && rounded ? 'rounded-[10%] *:rounded-[10%]' : (rounded ? 'rounded-full *:rounded-full' : '')
      )}
      {...props}
    >
      {initials && (
        <svg
          className="select-none fill-current text-[48px] font-medium uppercase m-auto"
          viewBox="0 0 100 100"
          aria-hidden={alt ? undefined : 'true'}
        >
          {alt && <title>{alt}</title>}
          <text x="50%" y="50%" alignmentBaseline="middle" dominantBaseline="middle" textAnchor="middle" dy=".125em">
            {initials}
          </text>
        </svg>
      )}
      {
        src && <img 
          src={src} 
          alt={alt} 
          style={{ 
            width: width ? width : (height ? '' : '28px'), 
            height: height ? height : '', 
            objectFit: 'cover',
            objectPosition: 'center'
          }} 
        />
      }
    </span>
  )
}

export const AvatarButton = React.forwardRef(function AvatarButton(
  {
    src,
    square = false,
    initials,
    alt,
    className,
    ...props
  }: AvatarProps & (HeadlessButtonProps | React.ComponentPropsWithoutRef<typeof Link>),
  ref: React.ForwardedRef<HTMLElement>
) {
  const classes = clsx(
    className,
    square ? 'rounded-lg' : 'rounded-full',
    'relative focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500'
  )

  return 'href' in props ? (
    <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <TouchTarget>
        <Avatar src={src} square={square} initials={initials} alt={alt} />
      </TouchTarget>
    </Link>
  ) : (
    <HeadlessButton {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Avatar src={src} square={square} initials={initials} alt={alt} />
      </TouchTarget>
    </HeadlessButton>
  )
})
