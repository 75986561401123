import React from 'react';
import {  } from '@headlessui/react';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const color_types = {
    warn: 'bg-orange-100 border-r-4 border-orange-500 text-orange-700',
    info: 'bg-green-100 border-r-4 border-green-500 text-green-700',
    error: 'bg-red-100 border-r-4 border-red-500 text-red-700'
}

const color_title_message = {
    warn: 'Warning',
    info: 'Info',
    error: 'Error'
}

interface SnackbarProps {
    colorType: keyof typeof color_types;
    text: string;
    showUpdate: (value: boolean) => void;
    show: boolean;
    dissapearIn?: number;
}

export function Snackbar({
    colorType,
    text,
    className,
    showUpdate,
    show = false,
    dissapearIn = 5,
    ...props
}: SnackbarProps & React.ComponentPropsWithoutRef<'div'>) {
    const [loading, setLoading] = React.useState<boolean>(show);

    React.useEffect(() => {
        setLoading(show);
        if (show) {
            setTimeout(() => {
                setLoading(false);
                showUpdate(false);
            }, dissapearIn * 1000)
        }
    }, [show]);

    return (
        <div 
            {...props} 
            className={
                clsx(
                    'p-4', 
                    color_types[colorType], 
                    className,
                    'transition-all fixed -bottom-4 right-4 z-50',
                    loading ? 'animate-loadUp' : 'animate-loadDown',
                )
            } 
            role="alert"
        >
            <div className='flex flex-row justify-between align-middle self-center text-right gap-x-6'>
                <div className='w-6 h-6'>
                    <XMarkIcon className="w-6-h-6" onClick={() => {
                        setLoading(false);
                        setTimeout(() => {
                            showUpdate(false)
                        }, 200);
                    }} />
                </div>
                <div className="flex flex-col">
                    <p className="font-bold">{ color_title_message[colorType] }</p>
                    <p>{ text }</p>
                </div>
            </div>
        </div>
    )
}