import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import Axios, { AxiosResponse } from "axios";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { 
    ICollection, 
    IUpdateCollectionRequest, 
    collectionsAtom, 
    shopifyCollectionsAtom, 
    collectionsLoadingAtom
} from "./types";


type SetCollectionType = (payload: ICollection[]) => void;
type SetShopifyCollectionType = (payload: ICollection[]) => void;
type FetchCollectionType = (brandId: number) => void;
type DeleteCollectionType = (mockup_id: number) => Promise<boolean>;
type AddCollectionType = (body: IUpdateCollectionRequest) => Promise<ICollection>;
type UpdateCollectionType = (body: IUpdateCollectionRequest) => Promise<ICollection>;

type CollectionKeyFunctions = {
    get: FetchCollectionType;
    delete: DeleteCollectionType;
    add: AddCollectionType;
    update: UpdateCollectionType;
}

type UseCollections = [
    ICollection[],
    ICollection[],
    SetCollectionType,
    SetShopifyCollectionType,
    CollectionKeyFunctions
]

export const useCollections = (): UseCollections => {
    const [collections, setCollections] = useAtom(collectionsAtom);
    const [shopifyCollections, setShopifyCollections] = useAtom(shopifyCollectionsAtom);
    const setCollectionsLoading = useSetAtom(collectionsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getCollections = (brandId: number) => {
        setCollectionsLoading(true);
        const fetchCollections = async (setter: SetCollectionType): Promise<void> => {
            const results = await Axios.get(`${environment.collectionsUrl}?brand_id=${brandId}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting collections. Please try again later.',
                })
            }
            setCollectionsLoading(false);
        }
        // send and forget
        fetchCollections(setCollections);
    }

    const addCollection = async (body: IUpdateCollectionRequest): Promise<ICollection> => {

        const form = new FormData();
        form.append('name', body.name);  
        form.append('brand_id', body.brand_id.toString());
        form.append('collection_logo', body.collection_logo);
        form.append('secondary_image', body.secondary_image);

        const insertResponse: AxiosResponse<ICollection> = await Axios.post(environment.collectionsUrl, form, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        setCollections([...collections, insertResponse.data]);
        setShopifyCollections([...shopifyCollections]);

        
        return insertResponse.data;
    }

    const deleteCollection = async (collectionId: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> = await Axios.delete(`${environment.collectionsUrl}?collection_id=${collectionId}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });
        if (deleteResponse.data) {
            setCollections(collections.filter((item: ICollection) => item.id !== collectionId));
        }
        return deleteResponse.data;
    }

    const updateCollection = async(body: IUpdateCollectionRequest): Promise<ICollection> => {
        const updateResponse: AxiosResponse<ICollection> = await Axios.put(environment.collectionsUrl, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        setCollections(collections.map((collection: ICollection) => {
            if (collection.id === body.id) {
                collection.collection_logo = updateResponse.data.collection_logo;
            }
            return collection;
        }))
        return updateResponse.data;
    }

    const keyFunctions: CollectionKeyFunctions = {
        add: addCollection,
        delete: deleteCollection,
        get: getCollections,
        update: updateCollection
    }

    return [
        collections,
        shopifyCollections,
        setCollections,
        setShopifyCollections,
        keyFunctions
    ]
}