import React from 'react';
import { tableFilterAtom } from './globalAtoms';
import { useAtom } from "jotai";

type CheckRowMatchType = (row: any) => boolean;
export type GetFilterMatchedRows<T> = (rows: T[], filter?: string) => T[];

export type FilterInfo<T> = {
    filter: {
        current: string,
        change: (value: string) => void,
    },
    getFilterMatchedRows: GetFilterMatchedRows<T>
}

type UseTableFilterType<T> = [
    FilterInfo<T>
];

interface FilterAtomType {
    component: string;
    currentFilter: string;
}

/**
 * 
 * @param {React.ReactElement} component
 * @param {string[]} filteredColumnKeys
 * @param {boolean} enableAtom
 */
export const useTableFilter = <T>(component: string, filteredColumnKeys: string[], enableAtom = true): UseTableFilterType<T> => {
    const [tableFilter, setTableFilter] = useAtom(tableFilterAtom);
    const [currentFilter, _setCurrentFilter] = React.useState<string>('');
    const [filteredKeys] = React.useState<string[]>(filteredColumnKeys);

    React.useEffect(() => {
        const atomHasComponent = tableFilter?.find((value: FilterAtomType) => value.component === component);
        if (!atomHasComponent && enableAtom) {
            setTableFilter([
                ...tableFilter,
                {
                    component: component,
                    currentFilter: '',
                }
            ])
        } else if (enableAtom) {
            _setCurrentFilter(atomHasComponent.currentFilter);
        }
    }, [component]);

    const filterMatchedRows: GetFilterMatchedRows<T> = (rows: T[]): T[] => {
        const result: T[] = [];
        const filter = atomHasComponent?.currentFilter || currentFilter;
        if (rows?.length && filter?.length) {
            rows.forEach((item: T) => {
                if (rowMatchesFilter(item)) {
                    result.push(item);
                }
            });
            return result;
        }
        return rows;
    }

    const setCurrentFilter = (currentFilter: string) => {
        _setCurrentFilter(currentFilter);
        if (tableFilter.length && enableAtom) {
            setTableFilter([...tableFilter.map((value: FilterAtomType) => {
                if (value.component === component) {
                    value.currentFilter = currentFilter;
                }
                return value;
            })])
        }
    }

    const rowMatchesFilter: CheckRowMatchType = (row: any, filter: string = atomHasComponent?.currentFilter || currentFilter): boolean => {
        if (!filter?.length) return true;
        const checkRowValue = (value: string, columnValue: any): boolean => {

            if (
                typeof columnValue === 'string' && columnValue
                    .toLowerCase()
                    .includes(
                        value.toLowerCase()
                    )
            ) {
                return true;
            }

            return typeof columnValue === 'number' && !isNaN(parseInt(value)) && columnValue === parseInt(value);
        }
        let result = false;
        // use standard for loop to break out early if needed
        for (let i = 0; i < filteredKeys.length; i++) {
            const splitKeys = filteredKeys[i].split('.');
            for (let key = 0; key < splitKeys.length; key++) {
                let column = (row as {[key: string]: string})[splitKeys[key]];
                let x = 1;
                while (typeof column === 'object') {
                    column = column[splitKeys[key + x++]]
                }
                result = checkRowValue(filter, column);
                if (result) break;
            }
            if (result) break;
        }
        return result;
    }

    const atomHasComponent: FilterAtomType | undefined = tableFilter.find((value: FilterAtomType) => value.component === component);

    return [
        {
            filter: {
                current: atomHasComponent?.currentFilter || currentFilter,
                change: setCurrentFilter,
            },
            getFilterMatchedRows: filterMatchedRows
        }
    ];
}