import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
    Field,
    Input,
    Label,
    Snackbar,
    Spinner,
    Text,
} from '../../components/UI';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../../globals/helpers';
import { useSnackbar, useFeatureFlags, useForm } from '../../hooks';
import { useUsers, ICreateAccountRequest } from '../../services';
import { analytics } from '../../index';

const imagesBucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';
const imageLink = `https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Log-In.png`;

const CreateAccount: React.FunctionComponent<{}> = (): JSX.Element => {

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [openTermsModal, setOpenTermsModal] = React.useState(false);
    const [,,AuthenticationFunctions] = useUsers();
    const [searchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useSnackbar();

    const [completedForm, setCompletedForm] = React.useState(false);

    const [FeatureFlagFunctions] = useFeatureFlags();

    const navigate = useNavigate();

    const form = useForm([
        {
            key: 'brand_id',
            validation: (value: number) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'company_name',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'first_name',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'last_name',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'email',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'password',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
        {
            key: 'terms',
            validation: (value: boolean) => {
                if (value == null) return 'Must agree to the terms of service';
                return null;
            },
            required: true
        }
    ]);

    React.useEffect(() => {
        checkFeatureFlags();
    }, []);
    
    const checkFeatureFlags = async () => {
        const result = await FeatureFlagFunctions.get('show-create-account');
        if (!result) {
            navigate('/login');
        }
    }

    const submitForm = async () => {
        setShowSpinner(true);      

        if (validatePassword(form.valueAtKey('password'))) {
            analytics.track('SIGNUP');

            const addValue: ICreateAccountRequest = {
                brand_id: parseInt(searchParams.get("brand_id") as string),
                is_new_brand: true,
                brand: { name: form.valueAtKey('company_name') },
                first: form.valueAtKey('first_name'),
                last: form.valueAtKey('last_name'),
                email: form.valueAtKey('email'),
                password: form.valueAtKey('password'),
                permission: 2
            }

            await AuthenticationFunctions.add(addValue);
            setCompletedForm(true);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'warn',
                text: 'Password is invalid. Please enter a password with the required parameters.',
            });
        }
        setShowSpinner(false);
    }

    return(
        <div className="w-screen h-screen relative">
            <div className="absolute t-0 l-0 w-full h-full z-0">
                <img 
                    src={imageLink}
                    className="w-full h-full"
                />
            </div>
            <div className="absolute t-0 l-0 w-full h-full z-20 bg-black/30"></div>
            <div className="absolute t-0 l-0 w-full h-full z-40">
                <div className="flex flex-row justify-center items-center w-full h-full">
                    <div className="w-full md:w-1/2 lg:w-2/3 xl:w-1/3 h-full md:h-auto lg:h-auto bg-slate-50 dark:bg-slate-800 shadow-lg border-2 border-slate-400 rounded-lg min-w-64">
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                            {
                                completedForm ? <>
                                    <h1 className='text-4xl text-gray-900 dark:text-white mb-5 text-center'>Account Created</h1>
                                    <p className="text-gray-900 dark:text-white mb-10">Your account has been created successfully. Please check you email for a verification link. Once you have verified your email, you will be able to login to Wallmates.</p>
                                    <div className="flex flex-row justify-between">
                                        <Text className="!font-bold pt-8 cursor-pointer" onClick={() => navigate('/login')}>Back To Login</Text>
                                    </div>
                                </>
                                : <>
                                    <h1 className='text-4xl text-gray-900 dark:text-white mb-5 text-center'>Create Account</h1>
                                    <Field>
                                        <Label>Company Name</Label>
                                        <Input type='text' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('company_name', ev.target.value)}></Input>
                                    </Field>
                                    <Field className='mt-3'>
                                        <Label>First Name</Label>
                                        <Input type='text' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('first_name', ev.target.value)}></Input>
                                    </Field>
                                    <Field className='mt-3'>
                                        <Label>Last Name</Label>
                                        <Input type='text' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('last_name', ev.target.value)}></Input>
                                    </Field>
                                    <Field className='mt-3'>
                                        <Label>Email</Label>
                                        <Input type='text' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('email', ev.target.value)}></Input>
                                    </Field>
                                    <Field className='mt-3'>
                                        <Label>Password</Label>
                                        <Input type='password' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('password', ev.target.value)} maxLength={64}></Input>
                                    </Field>
                                    <Field className='mt-3'>
                                        <Checkbox className="relative top-0.5 mr-1" id='terms' onChange={(checked: boolean) => form.setValueAtKey('terms', checked)} />
                                        <Label htmlFor='terms'> I agree to the </Label>
                                        <span className='text-emerald-500 cursor-pointer' onClick={() => setOpenTermsModal(true)}>Terms of Service</span>
                                    </Field>
                                    <p className='text-yellow-500 mt-5'>Password must contain at least 8 characters, one lowercase character, one uppercase character, one number and one special character.</p>
                                    <div className="flex flex-row justify-between">
                                        <Text className="!font-bold pt-8 cursor-pointer" onClick={() => navigate('/login')}>Back To Login</Text>
                                        <div className="flex justify-end border-stone-700 pt-6 gap-x-4">
                                            <Spinner show={showSpinner} />
                                            <Button onClick={ submitForm } color="emerald" id="create-account-button">Submit</Button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                fullScreen
                open={openTermsModal}
                onClose={() => setOpenTermsModal(false)}
            >
                <div className="flex flex-col w-full h-full">
                    <DialogTitle className="px-5">Wallmates Terms of Service</DialogTitle>
                    <DialogDescription className="px-5">
                        Welcome to Wallmates! These Terms of Service (&quot;Terms&quot;) govern your use of our website and the purchase of products from our online store. By accessing or using our website, you agree to be bound by these Terms. Please read them carefully before making any purchases.
                    </DialogDescription>
                    <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white absolute top-4 right-4"
                        data-modal-hide="default-modal"
                        onClick={() => setOpenTermsModal(false)}
                    >
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <hr className="mt-2"/>
                    <DialogBody className=" overflow-y-auto -m-6">
                        <div className="max-w-6xl mx-auto p-8 mt-10 text-zinc-900 dark:text-zinc-50">
                            <h1 className="text-3xl font-bold text-center mb-6">Wallmates Terms of Service</h1>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                                <p>Welcome to Wallmates! These Terms of Service (&quot;Terms&quot;) govern your use of our website
                                    and the purchase of products from our online store. By accessing or using our
                                    website,
                                    you agree to be bound by these Terms. Please read them carefully before making any
                                    purchases.</p>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Use of Website</h2>
                                <ul className="list-disc list-inside">
                                    <li><strong>Eligibility:</strong> You must be at least 18 years old to use our
                                        website and make
                                        purchases. By using our website, you represent and warrant that you meet this
                                        requirement.
                                    </li>
                                    <li><strong>Account Registration:</strong> To make a purchase, you may be required
                                        to create an
                                        account. You are responsible for maintaining the confidentiality of your account
                                        information and for all activities that occur under your account.
                                    </li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Intellectual Property</h2>
                                <ul className="list-disc list-inside">
                                    <li><strong>Ownership:</strong> All content on our website, including text,
                                        graphics, logos, and images,
                                        is the property of Wallmates or its content suppliers and is protected by
                                        intellectual property laws.
                                    </li>
                                    <li><strong>License:</strong> You are granted a limited, non-exclusive,
                                        non-transferable, and revocable
                                        license to access and use our website for personal, non-commercial purposes.
                                    </li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Limitation of Liability</h2>
                                <p>To the maximum extent permitted by law, Wallmates shall not be liable for any
                                    indirect,
                                    incidental, special, consequential, or punitive damages, or any loss of profits or
                                    revenues,
                                    whether incurred directly or indirectly, or any loss of data, use, goodwill, or
                                    other intangible
                                    losses, resulting from:</p>
                                <ul className="list-disc list-inside mt-2">
                                    <li>Your use or inability to use our website or products.</li>
                                    <li>Any unauthorized access to or use of our servers and/or any personal information
                                        stored therein.
                                    </li>
                                    <li>Any interruption or cessation of transmission to or from our website.</li>
                                    <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or
                                        through our website by any third party.
                                    </li>
                                    <li>Any errors or omissions in any content or for any loss or damage incurred as a
                                        result of your use of any content posted, emailed, transmitted, or otherwise
                                        made available through our website.
                                    </li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Venue and Choice of Law Clause</h2>
                                <p>All claims arising out of or relating to these terms shall be governed by the laws of
                                    the
                                    State of Arizona, and any legal action or proceeding shall be brought exclusively in
                                    the
                                    courts located in Arizona. However, any claims related to compliance with the
                                    Americans
                                    with Disabilities Act (ADA) shall be governed by the laws of the Commonwealth of
                                    Pennsylvania,
                                    and any such legal action or proceeding shall be brought exclusively in the courts
                                    located in Pennsylvania.</p>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Changes to Terms</h2>
                                <p>We reserve the right to modify these Terms at any time. Any changes will be effective
                                    immediately upon posting on our website. Your continued use of our website after any
                                    such changes constitutes your acceptance of the new Terms.</p>
                            </section>

                            <section className="mb-6">
                                <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
                                <p>If you have any questions about these Terms, please contact us at&nbsp;
                                    <a href="mailto:support@wallmates.com"
                                       className="text-blue-600 underline">support@wallmates.com</a>.</p>
                            </section>

                            <p className="text-center mt-6 text-zinc-700 dark:text-zinc-400">By using our website and purchasing our
                                products, you agree to these Terms of Service.</p>
                            <p className="text-center mt-2 font-semibold">Thank you for choosing Wallmates!</p>
                        </div>
                    </DialogBody>
                    <DialogActions>
                        <Button className="top-3 relative" onClick={() => setOpenTermsModal(false)} color='emerald'>Close</Button>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbar
                colorType={snackbar.snackbarLevel}
                show={snackbar.show}
                showUpdate={(value: boolean) => {
                    setSnackbar({text: snackbar.text, show: value, snackbarLevel: snackbar.snackbarLevel, duration: 5});
                }}
                text={snackbar.text}
                dissapearIn={snackbar.duration ? snackbar.duration : 5}
            />
        </div>
    )
}

export default CreateAccount;