import clsx from 'clsx';
import React from 'react';

interface LoaderProps {
    size?: number;
    reverse?: boolean;
}

const Loader: React.FunctionComponent<LoaderProps> = (props: LoaderProps): JSX.Element => {

    const { size = 96, reverse = false } = props;

    return (
        <div className="relative" style={{ width: size + 'px', height: size + 'px', padding: '0px', margin: '0px' }}>
            <svg 
                className={clsx(
                    reverse ? "fill-white/40 dark:fill-black/20" : "fill-black/20 dark:fill-white/40", 
                    "absolute t-0 l-0"
                )} 
                id="Layer_1" 
                data-name="Layer 1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 720 720" 
                width={size} 
                height={size}
            >
                <path className="loader" d="M 318.87 414.65 C 310.15 413.58 303.85 405.69 303.85 396.91 V 324 C 303.85 291.06 278.29 262.91 245.37 261.73 C 210.89 260.49 182.44 288.18 182.44 322.39 V 396.91 C 182.44 405.7 176.15 413.58 167.42 414.65 C 156.99 415.93 148.09 407.79 148.09 397.61 V 262.27 C 148.09 262.2 148.03 262.15 147.97 262.15 H 104.69 C 104.62 262.15 104.57 262.21 104.57 262.27 V 396 C 104.57 428.94 130.13 457.09 163.05 458.27 C 197.53 459.51 225.98 431.82 225.98 397.61 V 323.09 C 225.98 314.3 232.27 306.42 240.99 305.35 C 251.42 304.07 260.32 312.21 260.32 322.39 V 395.93 C 260.32 424.51 279.44 450.21 307.27 456.72 C 315.1 458.55 322.7 458.72 329.89 457.63 V 412.26 C 326.73 414.18 322.92 415.15 318.86 414.65 Z M 552.5 261.72 C 519.59 262.91 494.03 291.05 494.03 323.99 V 396.92 C 494.03 405.7 487.74 413.59 479.02 414.66 C 468.59 415.94 459.69 407.8 459.69 397.62 V 324.25 C 459.69 296.99 442.36 272.23 416.28 264.3 C 407.17 261.53 398.38 261.12 390.11 262.38 V 307.75 C 393.02 305.98 396.48 305.01 400.19 305.27 C 409.34 305.9 416.15 314.06 416.15 323.23 V 396.02 C 416.15 428.96 441.71 457.1 474.62 458.29 C 509.11 459.53 537.56 431.84 537.56 397.63 V 323.1 C 537.56 314.32 543.85 306.43 552.57 305.36 C 563 304.08 571.91 312.22 571.91 322.4 V 457.75 C 571.91 457.81 571.96 457.87 572.03 457.87 H 615.33 C 615.39 457.87 615.45 457.82 615.45 457.75 V 322.4 C 615.45 288.19 587 260.49 552.51 261.74 Z M 338.27 322.39 V 455.79 C 363.36 448.34 381.72 425.1 381.72 397.62 V 264.22 C 356.63 271.67 338.27 294.91 338.27 322.39 Z"/>
            </svg>
            <svg 
                className={clsx(
                    reverse ? "fill-white dark:fill-black" : "fill-black dark:fill-white",
                    "animate-clipper absolute t-0 l-0"
                )}
                id="Layer_1" 
                data-name="Layer 1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 720 720"
                width={size} 
                height={size}
            >
                <path className="loader" d="M 318.87 414.65 C 310.15 413.58 303.85 405.69 303.85 396.91 V 324 C 303.85 291.06 278.29 262.91 245.37 261.73 C 210.89 260.49 182.44 288.18 182.44 322.39 V 396.91 C 182.44 405.7 176.15 413.58 167.42 414.65 C 156.99 415.93 148.09 407.79 148.09 397.61 V 262.27 C 148.09 262.2 148.03 262.15 147.97 262.15 H 104.69 C 104.62 262.15 104.57 262.21 104.57 262.27 V 396 C 104.57 428.94 130.13 457.09 163.05 458.27 C 197.53 459.51 225.98 431.82 225.98 397.61 V 323.09 C 225.98 314.3 232.27 306.42 240.99 305.35 C 251.42 304.07 260.32 312.21 260.32 322.39 V 395.93 C 260.32 424.51 279.44 450.21 307.27 456.72 C 315.1 458.55 322.7 458.72 329.89 457.63 V 412.26 C 326.73 414.18 322.92 415.15 318.86 414.65 Z M 552.5 261.72 C 519.59 262.91 494.03 291.05 494.03 323.99 V 396.92 C 494.03 405.7 487.74 413.59 479.02 414.66 C 468.59 415.94 459.69 407.8 459.69 397.62 V 324.25 C 459.69 296.99 442.36 272.23 416.28 264.3 C 407.17 261.53 398.38 261.12 390.11 262.38 V 307.75 C 393.02 305.98 396.48 305.01 400.19 305.27 C 409.34 305.9 416.15 314.06 416.15 323.23 V 396.02 C 416.15 428.96 441.71 457.1 474.62 458.29 C 509.11 459.53 537.56 431.84 537.56 397.63 V 323.1 C 537.56 314.32 543.85 306.43 552.57 305.36 C 563 304.08 571.91 312.22 571.91 322.4 V 457.75 C 571.91 457.81 571.96 457.87 572.03 457.87 H 615.33 C 615.39 457.87 615.45 457.82 615.45 457.75 V 322.4 C 615.45 288.19 587 260.49 552.51 261.74 Z M 338.27 322.39 V 455.79 C 363.36 448.34 381.72 425.1 381.72 397.62 V 264.22 C 356.63 271.67 338.27 294.91 338.27 322.39 Z"/>
            </svg>
        </div>
    )
}

export default Loader;