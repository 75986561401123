import { useAtom } from "jotai";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { 
    IPricingTemplate, 
    IPopulatedPricingTemplate,
    IBrandPricing, 
    pricingTemplatesAtom, 
    populatedPricingTemplatesAtom, 
    internalPricingAtom, 
    pricingTemplatesLoadingAtom, 
    IPricingSource,
    pricingTemplateSourcesAtom,
    allPricingTemplateSourcesAtom
} from "./types";

type GetPricingTemplates = (brand_id: number) => Promise<IPricingTemplate[]>;
type GetPopulatedPricingTemplates = (brand_id: number) => Promise<IPopulatedPricingTemplate[]>;
type InsertPricingTemplate = (body: IPopulatedPricingTemplate) => Promise<IPopulatedPricingTemplate | null>;
type GetInternalBrandPricing = (brand_id: number) => Promise<IBrandPricing[] | null>;
type UpdateInternalBrandPricing = (body: IPopulatedPricingTemplate, isInternal: boolean) => Promise<IPopulatedPricingTemplate[] | null>;
type DeletePricingTemplate = (template_id: number) => Promise<boolean>;
type GetTemplateSources = (all_sources: boolean) => Promise<IPricingSource[] | null>;

type PricingKeyFunctions = {
    get: GetPricingTemplates,
    getPopulated: GetPopulatedPricingTemplates,
    insert: InsertPricingTemplate,
    getInternal: GetInternalBrandPricing,
    updateInternal: UpdateInternalBrandPricing,
    delete: DeletePricingTemplate,
    getTemplateSources: GetTemplateSources
}

type UsePricing = [
    IPricingTemplate[],
    IPopulatedPricingTemplate[],
    IBrandPricing[] | null,
    PricingKeyFunctions
];

export const usePricing = (): UsePricing => {
    const [templates, setTemplates] = useAtom(pricingTemplatesAtom);
    const [populatedTemplates, setPopulatedTemplates] = useAtom(populatedPricingTemplatesAtom);
    const [internalPricing, setInternalPricing] = useAtom(internalPricingAtom);
    const [,setTemplatesLoading] = useAtom(pricingTemplatesLoadingAtom);
    const [,setTemplateSources] = useAtom(pricingTemplateSourcesAtom);
    const [,setAllTemplateSources] = useAtom(allPricingTemplateSourcesAtom);
    
    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getTemplateSources = async (all_sources = false): Promise<IPricingSource[] | null> => {
        const getSources: AxiosResponse<IPricingSource[]> | void = await axios.get(`${environment.pricingUrl}/template-pricing-sources?all_sources=${all_sources}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (getSources) {
            if (!all_sources) {
                setTemplateSources(getSources.data);
            } else {
                setAllTemplateSources(getSources.data);
            }
            return getSources.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving pricing template sources. Please try again later.'
            });
            return [];
        }

    }

    const getPricingTemplates = async (brand_id: number): Promise<IPricingTemplate[]> => {
        setTemplatesLoading(true);
        const getTemplates: AxiosResponse<IPricingTemplate[]> | void = await axios.get(`${environment.pricingUrl}/pricing-templates?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getTemplates) {
            setTemplates(getTemplates.data);
            setTemplatesLoading(false);
            return getTemplates.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving pricing templates. Please try again later.'
            });
            setTemplatesLoading(false);
            return [];
        }
    }

    const getPopulatedPricingTemplates = async (brand_id: number): Promise<IPopulatedPricingTemplate[]> => {
        setTemplatesLoading(true);
        const getTemplates: AxiosResponse<IPopulatedPricingTemplate[]> | void = await axios.get(`${environment.pricingUrl}/populated-templates?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getTemplates) {
            setPopulatedTemplates(getTemplates.data);
            setTemplatesLoading(false);
            return getTemplates.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving pricing templates. Please try again later.'
            });
            setTemplatesLoading(false);
            return [];
        }
    }

    const insertPricingTemplate = async (body: IPopulatedPricingTemplate): Promise<IPopulatedPricingTemplate | null> => {
        const insertResponse: AxiosResponse<IPopulatedPricingTemplate> = await axios.post(environment.pricingUrl, body, {
            headers: {
                "Content-Type": "application/json",
                'X-Wallmates-Auth': authToken
            }
        });

        if (insertResponse) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Pricing Template Successfully Created.'
            });
            getPopulatedPricingTemplates(body.brand_id);
            return insertResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error creating pricing template.'
            });
            return null;
        }
    }

    const getBrandPricing = async (brand_id: number): Promise<IBrandPricing[] | null> => {
        const getResponse: AxiosResponse<IBrandPricing[]> | void = await axios.get(`${environment.pricingUrl}/brand-pricing?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getResponse) {
            setInternalPricing(getResponse.data);
            return getResponse.data;
        } else {
            setInternalPricing(null);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Could not get brand pricing.'
            });
            return null;
        }
    }
    
    const updateBrandPricing = async (body: IPopulatedPricingTemplate, isInternal = false): Promise<IPopulatedPricingTemplate[] | null> => {
        const updateResponse: AxiosResponse<IPopulatedPricingTemplate[]> = await axios.put(`${environment.pricingUrl}/brand-pricing?is_internal=${isInternal}`, body, {
            headers: {
                "Content-Type": "application/json",
                'X-Wallmates-Auth': authToken
            }
        });

        if (updateResponse) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Brand Pricing Successfully Updated.'
            });
            if (!isInternal) {
                getPopulatedPricingTemplates(body.brand_id);
            } else {
                getBrandPricing(body.brand_id);
            }
            return updateResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error updating brand pricing.'
            });
            return null;
        }
    }

    const deletePricingTemplate = async (template_id: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> | void = await axios.delete(`${environment.pricingUrl}?template_id=${template_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (deleteResponse) {
            const resetTemplatesResults = populatedTemplates.filter((item: IPopulatedPricingTemplate) => item.id !== template_id);
            setPopulatedTemplates(resetTemplatesResults);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Pricing Template Successfully Deleted.'
            });
            return deleteResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while deleting pricing template. Please try again later.'
            })
            return false;
        }
    }

    const keyFunctions: PricingKeyFunctions = {
        get: getPricingTemplates,
        getPopulated: getPopulatedPricingTemplates,
        insert: insertPricingTemplate,
        getInternal: getBrandPricing,
        updateInternal: updateBrandPricing,
        delete: deletePricingTemplate,
        getTemplateSources: getTemplateSources
    }

    return [
        templates,
        populatedTemplates,
        internalPricing,
        keyFunctions
    ]
}