import { atom } from "jotai";

export const generatedMockupsAtom = atom<IGeneratedMockupAtom>({});

export interface IGeneratedMockup {
    id: number;
    brand_id: number;
    mockup_url: string;
    product_id: number;
    mockup_id: number;
    attributes?: {
        pattern_scale: number;
        preview_width: number;
        shadow_opacity: number;
        enhance_shadows: boolean;
    }
}

export interface IGeneratedMockupAtom {
    [key: string]: IGeneratedMockup[];
}

export interface IGetGeneratedMockupRequest {
    product_id: number;
}