import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import { dashboardRoutes } from "./Routes";
import Dashboard from "./components/dashboard/Dashboard.component";
import Login from "./pages/login/Login.component";
import CreateUser from './pages/login/CreateUser.component';
import CreateAccount from './pages/login/CreateAccount.component';
import VerifyEmail from './pages/login/VerifyEmail.component';
import ForgotPassword from './pages/login/ForgotPassword.component';
import ResetPassword from './pages/login/ResetPassword.component';

export const router = createBrowserRouter([
    {
        element: <Dashboard />,
        children: dashboardRoutes
    },
    {
        element: <Login />,
        path: '/login'
    },
    {
        element: <CreateUser />,
        path: '/new-user'
    },
    {
        element: <CreateAccount />,
        path: '/new-account'
    },
    {
        element: <VerifyEmail />,
        path: '/verify'
    },
    {
        element: <ForgotPassword />,
        path: '/forgot-password'
    },
    {
        element: <ResetPassword />,
        path: '/reset-password'
    }
]);