import { atom } from "jotai";
import { IOrderLineItemDTO } from "../scanner";

export const orderLineItemsAtom = atom<IOrderLineItemDTO[]>([]);
export const orderLineItemsLoadingAtom = atom<boolean>(false);

export interface IGetOrderLineItemsQuery {
    order_id: number;
    brand_id: number;
}

export interface IUpdateOrderLineItemQuery {
    id?: number;
    order_id: number;
    design_id: number | null;
    material_id: number;
    width: number;
    height: string;
    print_id: string;
    line_item_jdml: string;
    jdml_dropped: boolean;
    droppable: boolean;
    print_range: string;
}

export interface IUploadGiftwrapQuery {
    id: number;
    order_id: number;
    giftwrapImage: string;
}