import { atom } from "jotai";

export const brandsAtom = atom<IBrand[]>([]);
export const brandsLoadingAtom = atom<boolean>(false);
export const loggedInBrandAtom = atom<IBrand | null>(null);
export const singleBrandAtom = atom<IBrand | null>(null);
export const singleBrandLoadingAtom = atom<boolean>(false);

export interface IBrand {
    id?: number;
    name: string;
    shopify_brand_name: string;
    top_up_amount?: number;
    waiting_payment?: boolean;
    static_header_rpe?: string;
    brand_logo?: string;
    rect_logo?: string;
    channels?: IChannel[];
    // shopify_api_key?: string;
    // shopify_api_secret?: string;
    // shopify_access_token?: string;
    production_enabled?: boolean;
    drop_pause_time?: number;
}

export interface IUploadLogoRequest {
    id?: number;
    name: string;
    shopify_brand_name: string;
    logo?: string;
    rect_logo?: string;
}

export interface IChannelPayload {
    store_name: string;
    store_domain: string;
    brand_id: number;
}

export interface IChannel {
    id?: number;
    created_at: string;
    enabled: boolean;
    connection_data: IShopifyConnectionData;
    channel_type: IChannelType;
    brand_id: number;
    channel_settings: IChannelSettings;
}

export interface IChannelSettings {
    is_order_source: boolean;
    is_product_creation_enabled: boolean;
}

export interface IShopifyConnectionData {
    shopify_brand_name: string;
    shopify_api_key: string;
    shopify_api_secret: string;
    shopify_access_token: string;
    download_link?: string;
}

export enum IChannelType {
    SHOPIFY = 'SHOPIFY',
    WALLMATES = 'WALLMATES'
}