import React from "react";

type UseFormValue = (key: string) => string;
type UseFormSetValue = (key: string, value: any) => void;
type UseFormFullValue = {[key: string]: any};
type UseFormSetKeys = (keys: FormKey<any>[], values?: any[] | null) => void;
type UseFormErrors = {[key: string]: string | null};
type UseFormIsValid = boolean;
type UseFormReset = () => void;

type UseForm = {
    valueAtKey: UseFormValue,
    setValueAtKey: UseFormSetValue,
    value: UseFormFullValue,
    updateFormKeys: UseFormSetKeys,
    errors: UseFormErrors,
    isValid: UseFormIsValid,
    reset: UseFormReset
}

export type ValidationFunction<T> = (value: T) => string | null;

export interface FormKey<T> {
    key: string;
    validation: ValidationFunction<T>;

    // default = true
    required?: boolean;
}


export const useForm = (keys: FormKey<any>[], values: any[] | null = null): UseForm => {
    const [formValidators, setFormValidators] = React.useState<FormKey<any>[]>(keys.map((formKey: FormKey<any>) => {
        return {
            key: formKey.key,
            validation: formKey.validation,
            required: formKey.required !== undefined ? formKey.required : true
        }
    }));

    const [formValue, setFormValue] = React.useState<{[key: string]: any}>(
        keys.reduce<{[key: string]: any}>((acc: {[key: string]: any}, key: FormKey<any>, index: number) => {
            if (values) {
                acc[key.key] = values[index];
            } else {
                acc[key.key] = '';
            }
            return acc;
        }, {})
    );
    
    const [errors, setErrors] = React.useState<{[key: string]: string | null}>(
        keys.reduce<{[key: string]: string | null}>((acc: {[key: string]: string | null}, key: FormKey<any>) => {
            if (values) {
                acc[key.key] = null;
            } else {
                acc[key.key] = null;
            }
            return acc;
        }, {})
    );

    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);

    const getValidation = (formKey: FormKey<any>, formValue: any): string | null => {
        return formKey?.validation(formValue);
    }

    const formValueAtKey = (key: string): string => {
        return formValue[key];
    }

    const getIsFormValid = () => {
        const isValid = (): boolean => {
            let result = true;
            Object.keys(errors).map((value: string) => {
                if (errors[value]) {
                    result = false;
                }
            })
            return result;
        }

        let isAnyRequiredEmpty = false;
        Object.keys(formValue).forEach((item: string) => {
            if (formValidators.find((validator: FormKey<any>) => validator.key === item)?.required) {
                if (formValue[item] === '') isAnyRequiredEmpty = true;
            }
        });

        return isValid() && !isAnyRequiredEmpty;
    }

    const setFormValueAtKey = (key: string, value: any): void => {
        setErrors({
            // have to spread into new object in order to trigger re-render
            ...Object.assign(errors, {
                [key]: getValidation(
                    formValidators.find((item: FormKey<any>) => item.key === key) as FormKey<any>, 
                    value
                )
            })
        });
        setFormValue({
            // have to spread into new object in order to trigger re-render
            ...Object.assign(formValue, {[key]: value})
        });
        setIsFormValid(getIsFormValid());
    }

    const setFormKeys = (keys: FormKey<any>[], values: any[] | null = null): void => {
        setFormValue(
            keys.reduce<{[key: string]: any}>((acc: {[key: string]: any}, key: FormKey<any>, index: number) => {
                if (values) {
                    acc[key.key] = values[index];
                } else {
                    acc[key.key] = '';
                }
                return acc;
            }, {})
        );
        setFormValidators(keys);
    }

    const reset = () => {
        setFormValue(
            formValidators.reduce<{[key: string]: any}>((acc: {[key: string]: any}, key: FormKey<any>, index: number) => {
                if (values) {
                    acc[key.key] = values[index];
                } else {
                    acc[key.key] = '';
                }
                return acc;
            }, {})
        );
        setIsFormValid(false);
    }

    const useForm: UseForm = {
        errors,
        isValid: isFormValid,
        reset,
        setValueAtKey: setFormValueAtKey,
        updateFormKeys: setFormKeys,
        value: formValue,
        valueAtKey: formValueAtKey
    }

    return useForm;
}