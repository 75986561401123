import { useAtom, useSetAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "./login";
import { 
    IUser, 
    IPermission, 
    ICreateAccountRequest, 
    ICreateUserRequest, 
    IUpdateUserRequest, 
    ICreateNewUserRequest, 
    IVerifyUserRequest, 
    IForgotPasswordRequest, 
    usersAtom, 
    permissionsAtom, 
    usersAtomLoading 
} from "./types";

type GetUsersType = (brand_id: number) => Promise<IUser[]>;
type GetPermissionsType = () => Promise<IPermission[]>;
type AddAccountType = (body: ICreateAccountRequest) => Promise<void>;
type UpdateUserType = (body: ICreateUserRequest) => Promise<IUser>;
type DeleteUserType = (user_id: number) => Promise<IUser | null>;
type UpdateUserPermissionsType = (body: IUpdateUserRequest) => Promise<IUser | null>;
type CreateUserType = (body: ICreateNewUserRequest) => Promise<IUser | null>;
type VerifyUserType = (body: IVerifyUserRequest) => Promise<void>;
type ForgotPasswordType = (body: IForgotPasswordRequest) => Promise<void>;

type AuthenticationKeyFunctions = {
    add: AddAccountType;
    update: UpdateUserType;
    get: GetUsersType,
    getPermissions: GetPermissionsType;
    deleteUser: DeleteUserType;
    updateUserPermissions: UpdateUserPermissionsType;
    createUser: CreateUserType;
    verify: VerifyUserType;
    forgotPassword: ForgotPasswordType;
}


export type UseCreateUser = [
    IUser[],
    IPermission[],
    AuthenticationKeyFunctions
];

export const useUsers = (): UseCreateUser => {

    const [users, setUsers] = useAtom(usersAtom);
    const [permissions, setPermissions] = useAtom(permissionsAtom);
    const setLoading = useSetAtom(usersAtomLoading);

    const [,authToken, loginFunctions] = useLogin();
    const [, setSnackbar] = useSnackbar();

    const addAccount = async (body: ICreateAccountRequest): Promise<void> => {
        const response = await Axios.post(`${environment.authenticationUrl}/create`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (!response) {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while creating your account. Please try again later.',
            })
        }
        return response.data;
    }

    const updateUser = async (body: ICreateUserRequest): Promise<IUser> => {
        const response = await Axios.post(`${environment.authenticationUrl}/update`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (!response) {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while creating your account. Please try again later.',
            })
        }
        return response.data;        
    }

    const getUsers = async (brand_id: number): Promise<IUser[]> => {
        setLoading(true);
        const response: AxiosResponse<IUser[]> | void = await Axios.get(`${environment.authenticationUrl}/users?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const users: IUser[] = response.data;
            setUsers(users);
            setLoading(false);
            return users;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting users. Please try again later.'
            })
            setUsers([]);
            setLoading(false);
            return [];
        }
    }

    const getPermissions = async (): Promise<IPermission[]> => {
        const response: AxiosResponse<IPermission[]> | void = await Axios.get(`${environment.authenticationUrl}/permissions`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const permissions: IPermission[] = response.data;
            setPermissions(permissions);
            return permissions;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting permissions. Please try again later.'
            })
            setPermissions([]);
            return [];
        }
    }

    const deleteUser = async (user_id: number): Promise<IUser | null> => {
        const response: AxiosResponse<IUser> | void = await Axios.delete(`${environment.authenticationUrl}/users?user_id=${user_id}`, {            
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        
        if (response) {
            const user: IUser = response.data;
            setUsers(users.filter((value: IUser) => {
                if (value.id === user_id) {
                    return false;
                }
                return true;
            }))
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error deleting user. Please try again later.'
            })
            return null;
        }
    }
    
    const verifyUser = async (body: IVerifyUserRequest): Promise<void> => {
        const response: AxiosResponse<IPermission[]> | void = await Axios.post(`${environment.authenticationUrl}/verify-email`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            console.log('response: ', response);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while verifying user. Please try again later.'
            });
            return;
        }
    }

    const updateUserPermissions = async (body: IUpdateUserRequest): Promise<IUser | null> => {
        const response: AxiosResponse<IUser> | void = await Axios.post(`${environment.authenticationUrl}/permissions`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const user: IUser = response.data;
            setUsers([...users.map((value: IUser) => {
                if (value.id === user.id) {
                    const permission = permissions.find((level: IPermission) => {
                        if (level.id === body.permission) {
                            return true;
                        }
                        return false;
                    });
                    (value.permissions as any).permission_id = permission as IPermission;
                }
                return value;
            })])
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error updating user permissions. Please try again later.'
            })
            return null;
        }
    }

    const createUser = async (body: ICreateNewUserRequest): Promise<IUser | null> => {
        const response: AxiosResponse<{ user: IUser }> | void = await Axios.post(`${environment.authenticationUrl}/create`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const user: IUser = response.data.user;
            setUsers([...users, user])
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error creating user. Please try again later.'
            })
            return null;
        }
    }

    const forgotPassword = async (body: IForgotPasswordRequest): Promise<void> => {
        const response: AxiosResponse<IUser> | void = await Axios.post(`${environment.authenticationUrl}/reset-password`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            console.log('response: ', response);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while verifying user. Please try again later.'
            });
            return;
        }
    }

    const keyFunctions: AuthenticationKeyFunctions = {
        add: addAccount,
        update: updateUser,
        get: getUsers,
        getPermissions,
        deleteUser,
        updateUserPermissions,
        createUser,
        verify: verifyUser,
        forgotPassword
    }

    return [
        users,
        permissions,
        keyFunctions
    ]
}