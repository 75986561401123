import { useAtom, useSetAtom } from "jotai";
import { errorCatcher, useSnackbar, socketWeightMessage } from "../../hooks";
import { useLogin } from "../login";
import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { IPrinter } from "../printers";
import { 
    IWeighQueue, 
    IWeightScanResponse, 
    weightScanItemLoadingAtom, 
    weighQueueAtom, 
    manualSearchLoadingAtom
} from "./types";

type ScanWeightItem = (query: IWeighQueue, printer: IPrinter) => Promise<IWeightScanResponse | null>;
type GetWeighQueue = () => Promise<IWeighQueue[]>;
type DeleteActiveBox = (activeWeight: IWeighQueue) => Promise<IWeighQueue | null>;
type ManualSearch = (search: string) => Promise<IWeighQueue[] | null>;
type SetWeightQueueStatusToUnprocessed = (queueId: number) => Promise<null>;

type UseWeightScannerKeyFunctions = {
    scan: ScanWeightItem;
    getWeighQueue: GetWeighQueue;
    deleteActiveBox: DeleteActiveBox;
    manualSearch: ManualSearch;
    setWeightQueueStatusToUnprocessed: SetWeightQueueStatusToUnprocessed;
}

type UseWeightScanner = [
    IWeighQueue[],
    UseWeightScannerKeyFunctions
];

export const useWeighScanner = (): UseWeightScanner => {
    const [, authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();
    const setScanLoading = useSetAtom(weightScanItemLoadingAtom);
    const [weighQueue, setWeighQueue] = useAtom(weighQueueAtom);
    const [, setSocketWeightMessage] = useAtom(socketWeightMessage);
    const setManualSearchLoading = useSetAtom(manualSearchLoadingAtom);

    const setWeightQueueStatusToUnprocessed: SetWeightQueueStatusToUnprocessed = async (queueId: number): Promise<null> => {
        const result: AxiosResponse<null> | void = await axios.post(`${environment.scanUrl}/weight-queue/unprocessed`,  { queueId }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (!result) {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error setting the weight queue status to unprocessed'
            })
            return null;
        }

        return null;
    }

    const scanItem = async (query: IWeighQueue, printer: IPrinter): Promise<IWeighQueue | null> => {
        setScanLoading(true);
        const weighQueue: AxiosResponse<IWeighQueue> | void = await axios.post(`${environment.scanUrl}/update-weight`, { weighQueue: query, printer }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            setScanLoading(false);
            return weighQueue.data;
        } else {
            setScanLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while performing scan'
            })
            return null;
        }
    }

    const getWeighQueue = async (): Promise<IWeighQueue[]> => {
        const weighQueue: AxiosResponse<IWeighQueue[]> | void = await axios.get(`${environment.scanUrl}/weight-queue`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            setWeighQueue(weighQueue.data);
            setSocketWeightMessage({
                "type": "weight",
                "device_turned_on": false,
                "weight": Math.round(Math.random() * 100) / 100,
                "device_plugged_in": false,
                "weight_type": 'Unknown'
            })
            return weighQueue.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error getting the weigh queue'
            })
            return [];
        }
    }

    const deleteActiveBox: DeleteActiveBox = async (activeWeight: IWeighQueue): Promise<IWeighQueue | null> => {
        const weighQueue: AxiosResponse<IWeighQueue> | void = await axios.delete(`${environment.scanUrl}/weight-queue?id=${activeWeight.id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            return weighQueue.data;      
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error deleting the weigh queue'
            })
            return null;
        }
    }

    const manualSearch: ManualSearch = async (search: string): Promise<IWeighQueue[] | null> => {
        setManualSearchLoading(true);
        const weighQueue: AxiosResponse<IWeighQueue[]> | void = await axios.get(`${environment.scanUrl}/weight-queue/search?search=${search}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            setManualSearchLoading(false);
            return weighQueue.data;
        } else {
            setManualSearchLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error searching for the weigh queue'
            })
            return null;
        }
    }

    const scanKeyFunctions: UseWeightScannerKeyFunctions = {
        scan: scanItem,
        getWeighQueue,
        deleteActiveBox,
        manualSearch,
        setWeightQueueStatusToUnprocessed
    }

    return [
        weighQueue,
        scanKeyFunctions
    ]
}