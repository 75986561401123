import { useAtom, useSetAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, snackbarSettingsAtom } from "../../hooks";
import { useLogin } from "../login";
import { 
    brandsAtom, 
    brandsLoadingAtom, 
    IBrand, 
    IChannelPayload, 
    IUploadLogoRequest, 
    loggedInBrandAtom, 
    singleBrandAtom, 
    singleBrandLoadingAtom 
} from "./types";

type LoggedInBrand = IBrand | null;
type SetBrandType = (payload: IBrand[]) => void;
type FetchBrandsType = () => void;
type GetBrandById = (id: number) => IBrand;
type GetBrand = (id: number) => Promise<IBrand | null>;
type UpdateBrand = (payload: Partial<IBrand>) => Promise<IBrand | null>;
type UploadLogoType = (payload: IUploadLogoRequest) => Promise<void>;
type UploadStaticHeaderType = (payload: FormData) => Promise<void>;
type connectChannelType = (payload: IChannelPayload) => Promise<void>;

type BrandKeyFunctions = {
    get: FetchBrandsType;
    getById: GetBrandById,
    getBrand: GetBrand,
    update: UpdateBrand,
    uploadLogo: UploadLogoType,
    uploadStaticHeader: UploadStaticHeaderType,
    connectChannel: connectChannelType
}

type UseBrands = [
    IBrand[],
    LoggedInBrand,
    IBrand | null,
    SetBrandType,
    BrandKeyFunctions
];

export const useBrands = (): UseBrands => {
    const [brands, setBrands] = useAtom(brandsAtom);
    const [loggedInBrand, setLoggedInBrand] = useAtom(loggedInBrandAtom)
    const setBrandsLoading = useSetAtom(brandsLoadingAtom);

    const setSnackbar = useSetAtom(snackbarSettingsAtom);
    const [user,authToken, loginFunctions] = useLogin();

    const [singleBrand, setSingleBrand] = useAtom(singleBrandAtom);
    const setSingleBrandLoading = useSetAtom(singleBrandLoadingAtom);

    const getBrands = () => {
        const fetchBrands = async (setter: SetBrandType): Promise<void> => {
            setBrandsLoading(true);
            const results = await Axios.get(environment.brandsUrl, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setLoggedInBrand(results.data.find((brand: IBrand) => brand.id === user?.brand_id) || null);
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting brands. Please try again later.'
                });
            }
            setBrandsLoading(false);
        }

        fetchBrands(setBrands);
    }

    const getById: GetBrandById = (id: number): IBrand => {
        return brands.find((value: IBrand) => value.id === id) as IBrand;
    }

    const getBrand = async (id: number): Promise<IBrand | null> => {
        setSingleBrandLoading(true);
        const getResponse: AxiosResponse<IBrand> | void = await Axios.get(`${environment.brandsUrl}/${id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getResponse) {
            setSingleBrandLoading(false);
            setSingleBrand(getResponse.data);
            return getResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting brand by id.'
            });
            setSingleBrandLoading(false);
            return null;
        }
    }

    const update = async (payload: Partial<IBrand>): Promise<IBrand | null> => {
        const updateResponse: AxiosResponse<IBrand> | void = await Axios.put(environment.brandsUrl, payload, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (updateResponse) {
            setLoggedInBrand(updateResponse.data);
            return updateResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while updating brand.'
            });
            return null;
        }
    }

    const uploadLogo = async (payload: IUploadLogoRequest): Promise<void> => {
        setSingleBrandLoading(true);
        const uploadResponse: AxiosResponse<string> = await Axios.post(`${environment.brandsUrl}/logo`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        if (uploadResponse) {
            const updatedBrand: IBrand = {
                id: payload.id,
                name: payload.name,
                shopify_brand_name: payload.shopify_brand_name
            }
            if (payload.logo) {
                updatedBrand.brand_logo = uploadResponse.data;
            }
            if (payload.rect_logo) {
                updatedBrand.rect_logo = uploadResponse.data; 
            }
            setSingleBrand(updatedBrand);
            setSingleBrandLoading(false);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while uploading brand logo.'
            });
            setSingleBrandLoading(false);
        }
    }

    const uploadStaticHeader = async (payload: FormData): Promise<void> => {
        const uploadResponse = await Axios.put(`${environment.brandsUrl}/header`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (uploadResponse) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Header uploaded successfully.'
            });
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while uploading header.'
            });
        }
    }

    const connectChannel = async (payload: IChannelPayload): Promise<void> => {
        const updateResponse = await Axios.post(`${environment.brandsUrl}/connect-channel`, payload, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (updateResponse) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Channel settings updated successfully.'
            });
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while updating channel settings.'
            });
        }
    }
    
    const keyFunctions: BrandKeyFunctions = {
        get: getBrands,
        getById,
        getBrand,
        update,
        uploadLogo,
        uploadStaticHeader,
        connectChannel
    }

    return [
        brands,
        loggedInBrand,
        singleBrand,
        setBrands,
        keyFunctions
    ]
}