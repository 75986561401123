import { useAtom } from "jotai/index";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { PaginatedResponseType } from "../../globals/types";
import { buildQueryStringFromParams } from "../../components/helpers";
import { 
  IGetCustomersQuery, 
  ICustomer, 
  ICreateCustomerRequest, 
  IUpdateCustomerAddressRequest, 
  ICustomerAddress, 
  IUpdateCustomerRequest, 
  ICustomerType, 
  ICustomerAddressType, 
  customersAtom, 
  customerAtom, 
  customerTypesAtom, 
  customerAddressTypesAtom, 
  customersLoadingAtom, 
  customerLoadingAtom 
} from "./types";

type GetCustomersType = (data: IGetCustomersQuery) => Promise<PaginatedResponseType<ICustomer> | void>;
type GetCustomerByIdType = (customer_id: ICustomer["id"], view?: string) => Promise<ICustomer | null>;
type CreateCustomerType = (body: ICreateCustomerRequest) => Promise<ICustomer | null>;
type CreateCustomerAddressType = (body: IUpdateCustomerAddressRequest) => Promise<ICustomerAddress | null>;
type UpdateCustomerType = (body: IUpdateCustomerRequest) => Promise<ICustomer | null>;
type UpdateCustomerAddressType = (body: IUpdateCustomerAddressRequest) => Promise<ICustomerAddress | null>;
type DeleteCustomerType = (customer_id: ICustomer["id"]) => Promise<boolean | void>;
type DeleteCustomerAddressType = (address_id: ICustomerAddress["id"]) => Promise<boolean | void>;
type GetCustomerTypes = () => Promise<ICustomerType[] | void>;
type GetCustomerAddressTypes = () => Promise<ICustomerAddressType[] | void>;

type CustomerKeyFunctions = {
  getByID: GetCustomerByIdType,
  get: GetCustomersType,
  create: CreateCustomerType,
  createAddress: CreateCustomerAddressType,
  update: UpdateCustomerType,
  updateAddress: UpdateCustomerAddressType,
  delete: DeleteCustomerType,
  deleteAddress: DeleteCustomerAddressType
  getCustomerTypes: GetCustomerTypes,
  getCustomerAddressTypes: GetCustomerAddressTypes,
}

type UseCustomers = [
  PaginatedResponseType<ICustomer>,
  ICustomer,
  ICustomerType[],
  ICustomerAddressType[],
  CustomerKeyFunctions
];

export const useCustomers = (): UseCustomers => {
  const [customers, setCustomers] = useAtom(customersAtom);
  const [customer, setCustomer] = useAtom(customerAtom);
  const [customerTypes, setCustomerTypes] = useAtom(customerTypesAtom);
  const [customerAddressTypes, setCustomerAddressTypes] = useAtom(customerAddressTypesAtom);
  const [, setCustomersLoading] = useAtom(customersLoadingAtom);
  const [, setCustomerLoading] = useAtom(customerLoadingAtom);

  const [, setSnackbar] = useSnackbar();
  const [, authToken, loginFunctions] = useLogin();

  const getCustomers = async (query: IGetCustomersQuery): Promise<PaginatedResponseType<ICustomer> | void> => {
    setCustomersLoading(true);
    return await axios.get(`${environment.customersUrl}${buildQueryStringFromParams(query)}`, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    })
      .then((customers: AxiosResponse<PaginatedResponseType<ICustomer>>) => {
        if (customers?.data) {
          setCustomers(customers.data);
          return customers.data;
        } else {
          setSnackbar({
            show: true,
            snackbarLevel: 'error',
            text: 'There was an error retrieving customers. Please try again later.',
          });
        }
      })
      .catch((err: any) => {
        errorCatcher(err, loginFunctions.logout)
      })
      .finally(() => setCustomersLoading(false));
  }

  const getCustomerById = async (customer_id: ICustomer["id"], view = 'list'): Promise<ICustomer | null> => {
    setCustomerLoading(true);
    const customer: AxiosResponse<ICustomer> | void = await axios.get(`${environment.customersUrl}/${customer_id}?view=${view}`, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (customer?.data) {
      setCustomer(customer.data);
      setCustomerLoading(false);
      return customer.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error retrieving this customer. Please try again later.',
      });
      setCustomerLoading(false);
      return null;
    }
  }

  const createCustomer = async (body: ICreateCustomerRequest): Promise<ICustomer | null> => {
    const response: AxiosResponse<ICustomer> = await axios.post(environment.customersUrl, body, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Successfully Created.',
      });
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error creating pricing template.',
      });
      return null;
    }
  }

  const createCustomerAddress = async (body: IUpdateCustomerAddressRequest): Promise<ICustomerAddress | null> => {
    const response: AxiosResponse<ICustomerAddress> = await axios.post(`${environment.customersUrl}/address`, body, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Successfully Created.',
      });
       setCustomer({...customer, addresses: [...customer.addresses, response.data]});
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error creating pricing template.',
      });
      return null;
    }
  }

  const updateCustomer = async (body: IUpdateCustomerRequest): Promise<ICustomer | null> => {
    const response: AxiosResponse<ICustomer> = await axios.put(environment.customersUrl, body, {
      headers: {
        "Content-Type": "application/json",
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Successfully Updated.',
      });
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error updating this customer. Please try again later.',
      });
      return null;
    }
  }

  const updateCustomerAddress = async (body: IUpdateCustomerAddressRequest): Promise<ICustomerAddress | null> => {
    const response: AxiosResponse<ICustomerAddress> = await axios.put(`${environment.customersUrl}/address`, body, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Address Successfully Updated.',
      });
      const index = customer.addresses.findIndex((address) => address.id === body.id);
      if (index > -1) {
        customer.addresses[index] = response.data;
        setCustomer({...customer});
      }
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error updating this customer\'s address. Please try again later.',
      });
      return null;
    }
  }

  const deleteCustomer = async (customer_id: ICustomer["id"]): Promise<void | boolean> => {
    const response: AxiosResponse<boolean> | void = await axios.delete(`${environment.customersUrl}/${customer_id}`, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Successfully Deleted.',
      });
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error while deleting pricing template. Please try again later.',
      })
      return false;
    }
  }
  const deleteCustomerAddress = async (address_id: ICustomerAddress["id"]): Promise<void | boolean> => {
    const response: AxiosResponse<boolean> | void = await axios.delete(`${environment.customersUrl}/address/${address_id}`, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
    if (response?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Customer Address Successfully Deleted.',
      });
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error while deleting this customer\'s address. Please try again later.',
      })
      return false;
    }
  }

  const getCustomerTypes = async (): Promise<ICustomerType[] | void> => {
    const response: AxiosResponse<ICustomerType[]> = await axios.get(`${environment.customersUrl}/types`, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setCustomerTypes(response.data);
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error retrieving customer types. Please try again later.',
      });
    }
  }

  const getCustomerAddressTypes = async (): Promise<ICustomerAddressType[] | void> => {
    const response: AxiosResponse<ICustomerAddressType[]> = await axios.get(`${environment.customersUrl}/address/types`, {
      headers: {
        'X-Wallmates-Auth': authToken,
      },
    });

    if (response?.data) {
      setCustomerAddressTypes(response.data);
      return response.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an error retrieving customer address types. Please try again later.',
      });
    }
  }

  const keyFunctions: CustomerKeyFunctions = {
    getByID: getCustomerById,
    get: getCustomers,
    create: createCustomer,
    createAddress: createCustomerAddress,
    update: updateCustomer,
    updateAddress: updateCustomerAddress,
    delete: deleteCustomer,
    deleteAddress: deleteCustomerAddress,
    getCustomerTypes: getCustomerTypes,
    getCustomerAddressTypes: getCustomerAddressTypes,
  }

  return [
    customers,
    customer,
    customerTypes,
    customerAddressTypes,
    keyFunctions,
  ]
}