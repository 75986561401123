import { atom, useAtom } from "jotai";
import { color_types } from "../components/UI";
import { AxiosError } from "axios";

export const errorCatcher = (error: AxiosError, logout: () => void) => {
    if (error.response) {
        console.error("ERROR DATA:", error.response.data);
        console.error("ERROR STATUS CODE:", error.response.status);
        console.error("ERROR HEADERS:", error.response.headers);
        if ((error.response.data as any)['status'] && (error.response.data as any)['status'] === 401) {
            logout();
        }
    } else if (error.request) {
        console.error("REQUEST ERROR:", error.request);
    } else {
        console.error("AXIOS UNKNOWN ERROR:", error.message);
    }
    console.error("ERROR CONFIG:",error.config);
}

export const errorMessageCatcher = (error: AxiosError, logout: () => void): string => {
    if (error.response) {
        console.error("ERROR DATA:", error.response.data);
        console.error("ERROR STATUS CODE:", error.response.status);
        console.error("ERROR HEADERS:", error.response.headers);
        if ((error.response.data as any)['status'] && (error.response.data as any)['status'] === 401) {
            logout();
        }
        return (error.response.data as any)?.trace?.raw.message || '';
    } else if (error.request) {
        console.error("REQUEST ERROR:", error.request);
        return '';
    } else {
        console.error("AXIOS UNKNOWN ERROR:", error.message);
        return '';
    }
}

interface SnackbarSettings {
    text: string;
    snackbarLevel: keyof typeof color_types;
    show: boolean;
    duration?: number;
}

export const snackbarSettingsAtom = atom<SnackbarSettings>({ text: '', snackbarLevel: 'info', show: false, duration: 5 });

type SetSnackbarSettings = (settings: SnackbarSettings) => void;

type UseSnackbarType = [
    SnackbarSettings,
    SetSnackbarSettings,

]

export const useSnackbar = (): UseSnackbarType => {
    const [snackbarSettings, setSnackbarSettings] = useAtom(snackbarSettingsAtom);

    return [
        snackbarSettings,
        setSnackbarSettings
    ]
}