

type SetTitle = (title: string) => void;
export type UseSetTitle = SetTitle

export const useSetTitle = (): UseSetTitle => {

    const setTitle = (title: string) => {
        document.title = "Wallmates | " + title;
    }

    return setTitle
}