import React from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from "react";
import { IBrand, useBrands, useLogin } from '../../../services';
import { Avatar } from '../avatar/Avatar.component';

export interface FlyoutProps {
    title: string;
    icon: any;
}

export const Flyout: React.FunctionComponent<PropsWithChildren<FlyoutProps>> = (props: PropsWithChildren<FlyoutProps>): JSX.Element => {
    const { children, title } = props;

    const [,,singleBrand,, brandKeyFunctions] = useBrands();
    const [brand, setBrand] = React.useState<IBrand | null>(null);
    const [user] = useLogin();

    React.useEffect(() => {
        setBrand(null);
        if (user) {
            brandKeyFunctions.getBrand(user.brand_id);
        }
    }, []);

    React.useEffect(() => {
        if (singleBrand) {
            setBrand(singleBrand);
        }
    }, [singleBrand]);

    return (
        <Popover className="relative w-full rounded-md dark:text-gray-100 dark:hover:bg-stone-800 text-gray-600 hover:bg-stone-300">
            <PopoverButton className="inline-flex flex-grow gap-x-1 items-center text-sm font-semibold leading-6 outline-none p-2 justify-center">
                {
                    brand && brand.brand_logo ? 
                    <Avatar square={false} src={brand.brand_logo} height='25px' width='25px'></Avatar> :
                    <UserCircleIcon key={"profile-icon"} className="h-5 w-5 shrink-0" />
                }
                <span className='text-xs ml-3'>{title}</span>
            </PopoverButton>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <PopoverPanel className="absolute z-50 flex lg:left-48 lg:bottom-12 bottom-14 px-4 w-screen max-w-min">
                    <div className="w-56 shrink bg-white dark:bg-stone-600 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 rounded-md">
                        {children}
                    </div>
                </PopoverPanel>
            </Transition>
        </Popover>
    )
}