import { atom } from "jotai";

export const designHeadersAtom = atom<IDesignHeader[]>([]);

export interface IDesignHeader {
    id?: number;
    design_name: string;
    brand_id: string;
    header_jdml: string;
    lead_rpe: string;
    mid_rpe: string;
    end_rpe: string;
    header_design_template: number;
    bucket_folder: string;
    design_id: number;
}