import { atom } from "jotai";

export const collectionsAtom = atom<ICollection[]>([]);
export const collectionsLoadingAtom = atom<boolean>(false);
export const shopifyCollectionsAtom = atom<ICollection[]>([]);
export const shopifyCollectionsLoadingAtom = atom<boolean>(false);

export interface ICollection {
    id?: number;
    name: string;
    artist_name?: string;
    font_id?: number;
    brand_id: number;
    collection_logo: string;
    secondary_image: string;
    signature?: string;
    use_signature_image?: boolean;
    message?: string;
}

export interface IUpdateCollectionRequest {
    id?: number;
    name: string;
    brand_id: number;
    collection_logo: string;
    secondary_image: string;
}