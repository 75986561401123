import axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login/login";
import { GetInvoicesBody, IInvoice, invoicesAtom, invoicesLoadingAtom, singleInvoiceAtom } from "./types";
import { PaginatedResponseType } from "../../globals/types";

type GetInvoices = (body: GetInvoicesBody) => Promise<PaginatedResponseType<IInvoice> | null>;
type GetInvoice = (invoiceId: number) => Promise<IInvoice | null>;

type InvoiceKeyFunctions = {
    get: GetInvoices,
    getSingle: GetInvoice
}

type UseInvoices = [
    PaginatedResponseType<IInvoice>,
    IInvoice | null,
    InvoiceKeyFunctions
]

export const useInvoices = (): UseInvoices => {
    const [invoice, setInvoice] = useAtom(singleInvoiceAtom);
    const [invoices, setInvoices] = useAtom(invoicesAtom);
    const setInvoicesLoading = useSetAtom(invoicesLoadingAtom);
    const [,setSnackbar] = useSnackbar();
    const [, authToken, loginFunctions] = useLogin();

    const getInvoices = async (body: GetInvoicesBody): Promise<PaginatedResponseType<IInvoice> | null> => {
        setInvoicesLoading(true);
        const getInvoices: AxiosResponse<PaginatedResponseType<IInvoice>> | void = await axios.post(`${environment.invoicesUrl}`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getInvoices) {
            setInvoices(getInvoices.data);
            setInvoicesLoading(false);
            return getInvoices.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving invoices. Please try again later.'
            });
            setInvoicesLoading(false);
            return null;
        }
    }

    const getInvoice = async (invoiceId: number): Promise<IInvoice | null> => {
        setInvoicesLoading(true);
        const getInvoice: AxiosResponse<IInvoice> | void = await axios.get(`${environment.invoicesUrl}/${invoiceId}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getInvoice) {
            setInvoice(getInvoice.data);
            setInvoicesLoading(false);
            return getInvoice.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving the invoice. Please try again later.'
            });
            setInvoicesLoading(false);
            return null;
        }
    }

    const keyFunctions: InvoiceKeyFunctions = {
        get: getInvoices,
        getSingle: getInvoice
    }

    return [
        invoices,
        invoice,
        keyFunctions
    ]
}

