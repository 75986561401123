import { useAtom } from "jotai";
import Axios from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { INotification, notificationsAtom, notificationsLoadingAtom } from "./types";

type SetMockupCatoryType = (payload: INotification[]) => void;
type FetchMockupCategories = () => void;

type NotificationKeyFunctions = {
    get: FetchMockupCategories
}

type UseNotifications = [
    INotification[],
    SetMockupCatoryType,
    NotificationKeyFunctions
];

export const useNotifications = (brand: number): UseNotifications => {
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [,setActionLoading] = useAtom(notificationsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getMockupCategories = () => {
        const fetchNotifications = async (setter: SetMockupCatoryType): Promise<void> => {
            setActionLoading(true);
            const result = await Axios.get(`${environment.queuesUrl}/image-processor?brand_id=${brand}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (result) {
                setter(result.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting Notifications. Please try again later.'
                })
            }
            setActionLoading(false);
        }
        fetchNotifications(setNotifications);
    }

    const keyFunctions: NotificationKeyFunctions = {
        get: getMockupCategories
    }

    return [
        notifications,
        setNotifications,
        keyFunctions
    ]
}