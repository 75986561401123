import axios, { AxiosResponse } from "axios";
import { useAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IDesignHeader, designHeadersAtom } from "./types";

type SetDesignHeaderType = (payload: IDesignHeader[]) => void;

type FetchDesignHeadersType = (brand_id: number) => Promise<IDesignHeader[] | null>;
type GetDesignHeaderByIdType = (id: number) => IDesignHeader;

type DesignHeaderKeyFunctions = {
    get: FetchDesignHeadersType,
    getByDesignId: GetDesignHeaderByIdType
}

type UseDesignHeaders = [
    IDesignHeader[],
    SetDesignHeaderType,
    DesignHeaderKeyFunctions
];

export const useDesignHeaders = (): UseDesignHeaders => {
    const [designHeaders, setDesignHeaders] = useAtom(designHeadersAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const keyFunctions: DesignHeaderKeyFunctions = {
        get: async (brand_id: number): Promise<IDesignHeader[] | null> => {
            const getResponse: AxiosResponse<IDesignHeader[]> | void = await axios.get(`${environment.designHeadersUrl}?brand_id=${brand_id}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (getResponse) {
                setDesignHeaders(getResponse.data);
                return getResponse.data;
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting design headers. Please try again later.'
                })
                return null;
            }
        },
        getByDesignId: (id: number) => {
            return designHeaders.find((header: IDesignHeader) => header.design_id === id) as IDesignHeader;
        }
    }

    return [
        designHeaders,
        setDesignHeaders,
        keyFunctions
    ]
}