import clsx from 'clsx';
import { PrimitiveAtom, useAtom } from 'jotai';
import React from 'react';
import { HeroIcon } from '../../dashboard/Dashboard.types';

export interface TabsContainer {
    stateAtom: PrimitiveAtom<number>;
    tabOnly?: boolean;
}

export type TabsContainerType = TabsContainer & React.ComponentPropsWithoutRef<'div'>;

export const TabsContainer: React.FunctionComponent<TabsContainerType> = (props: TabsContainerType): JSX.Element => {
    const { className, stateAtom, children, tabOnly = false, ...remaining } = props;

    const [activeTab, setActiveTab] = useAtom(stateAtom);

    return (
        <div 
            className={clsx(
                className,
                'flex flex-col box transition-all'
            )}
            { ...remaining }
        >
            <div 
                className='flex flex-row border-b border-stone-400 dark:border-stone-600 h-12'
            >
                { 
                    React.Children.map(children, (child: React.ReactNode, index: number) => {
                        if (React.isValidElement(child)) {
                            return (
                                <div
                                    key={ child.key }
                                    className={
                                        clsx(
                                            'w-64 h-12 block justify-center center cursor-pointer transition-all',
                                            'border-t border-l last:border-r border-stone-400 dark:border-stone-600',
                                            'first:rounded-tl-md last:rounded-tr-md',
                                            activeTab === index ?
                                            'border-y-4 border-y-emerald-500 dark:border-y-emerald-400' :
                                            'border-b bg-stone-200 dark:bg-stone-800 hover:bg-stone-100 dark:hover:bg-stone-900'
                                        )
                                    }
                                    onClick={ () => setActiveTab(index) }
                                >
                                    <div
                                        className={
                                            clsx(
                                                "w-full h-full flex self-center text-center justify-center items-center font-bold",
                                                activeTab !== index ?
                                                'text-stone-400 dark:text-stone-600' :
                                                'text-black dark:text-white'
                                            )
                                        }
                                    >
                                        {
                                            child.props.tabIcon &&
                                            <>
                                                <div className="hidden md:flex flex-row justify-start gap-x-4 w-full px-4">
                                                    <span>
                                                        <child.props.tabIcon className="w-6 h-6" />
                                                    </span>
                                                    <span>
                                                        { child.props.tabName }
                                                    </span>
                                                </div>

                                                <span className="contents md:hidden">
                                                    <child.props.tabIcon className="w-6 h-6" />
                                                </span>
                                            </>
                                        }

                                        {
                                            !child.props.tabIcon &&
                                            <>
                                                <div className="hidden md:flex flex-row justify-start gap-x-4 w-full px-4">
                                                    <span>
                                                        { child.props.tabName }
                                                    </span>
                                                </div>

                                                <span className="contents md:hidden">
                                                    { child.props.tabName }
                                                </span>
                                            </>
                                        }
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }) 
                }
            </div>
            {
                tabOnly === false ?
                <div 
                    className={
                        clsx(
                            'border-x border-b border-t md:border-t-0 border-stone-400 dark:border-stone-600 rounded-b-md',
                            'shadow-stone-200 dark:shadow-stone-800 shadow-md',
                            'flex h-full'
                        )
                    }
                >
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (activeTab !== index) return null;

                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, child.props);
                            }
                        })
                    }
                </div> : null
            }
        </div>
    )
}

export interface Tab {
    key: string;
    tabName: string;
    tabIcon?: HeroIcon;
}

export type TabType = Tab & React.ComponentPropsWithoutRef<'div'>;

export const Tab: React.FunctionComponent<TabType> = (props: TabType): JSX.Element => {
    
    const { children } = props;

    return (
        <div
            className="p-4 w-full"
        >
            { children }
        </div>
    )
}