import { atom } from "jotai";

export const mockupCategoryAtom = atom<IMockupCategory[]>([]);

export interface IMockupCategory {
    id?: number;
    name: string;
    category_type: ICategoryType;
}

export enum ICategoryType {
    ROOM_TYPE = 'ROOM_TYPE',
    STYLE_TYPE = 'STYLE_TYPE',
    COLOR_TYPE = 'COLOR_TYPE'
}
