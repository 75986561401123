export const buildQueryStringFromParams = (params: Record<string, any>): string => {
  if (Object.entries(params).length === 0) {
    return '';
  }
  let queryString = '?';
  Object.entries(params).forEach(([key, value]) => {
    if (key === 'filter_by') {
      value = JSON.stringify(value);
    }
    if (value !== null && typeof value !== 'undefined' && value !== '') {
        queryString += `${key}=${value}&`;
    }
  });
  return queryString.substring(0, queryString.length - 1);
}