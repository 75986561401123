import clsx from "clsx";
import React from 'react';
import { Text } from "../text/Text.component";
import { Spinner } from "../spinner/Spinner.component";

export interface GalleryProps {
    onClickItem?: (value: string) => void;
    useProp?: string;
}

export type GalleryType = GalleryProps & React.ComponentProps<'div'>;

export const Gallery: React.FunctionComponent<GalleryType> = (props: GalleryType): JSX.Element => {

    const { className, onClickItem, children, useProp = 'title', ...remaining } = props;

    return (
        <div
            className={
                clsx(className, 'grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] auto-rows-min gap-4 break-inside-avoid')
            }
            {...remaining}
        >

            {
                React.Children.map(children, (child: React.ReactNode) => {
                    if (React.isValidElement(child)) {
                        return (
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            <span onClick={onClickItem ? () => onClickItem(child.props[useProp]) : () => { }}>
                                {child}
                            </span>
                        )
                    }
                })
            }
        </div>
    )
}

export interface GalleryItem {
    imageUrl: string;
    title: string;
    isLoading?: boolean;
}

export type GalleryItemType = GalleryItem & React.ComponentPropsWithoutRef<'div'>;

export const GalleryItem: React.FunctionComponent<GalleryItemType> = (props: GalleryItemType): React.ReactElement => {

    const [isLoaded, setIsLoaded] = React.useState(false);

    const { imageUrl, title, isLoading = false, ...remaining } = props;



    return (
        <div className={clsx("flex flex-col text-center gap-y-2 h-[400px]", isLoading ? 'loading' : '')}>
            <div
                className={clsx(
                    'relative h-full',
                )}
                {...remaining}
            >
                {
                    !isLoaded ? <div className="w-full max-w-full h-[350px] rounded-lg bg-slate-200"></div> : null
                }
                <div className="relative" style={{ boxShadow: 'inset var(--tw-ring-offset-shadow, 0 0 #0000)' }}>
                    <img
                        className="w-full object-cover rounded-lg h-[350px]"
                        src={imageUrl}
                        alt={title}
                        onLoad={() => setIsLoaded(true)}
                        style={{ display: isLoaded ? 'block' : 'none' }}
                    />
                    {isLoading && (
                        <div className="absolute bottom-0 left-0 h-9 w-full opacity-60 bg-black flex justify-items-start rounded-b-lg pl-3">
                            <Spinner reverse={true} show={true} size={40} />
                            <div className="text-slate-300 size-4 w-full ml-3 -mt-0.5 inline-flex opacity-90 pt-2">
                                Loading Preview...
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="px-4">
                {
                    title && title !== '' ?
                        <Text> {title} </Text> : null
                }
            </div>
        </div>
    )
}